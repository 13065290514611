import React from "react";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
import Helmet from "react-helmet";

export default class NewsIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>NEWS & Views From Globelynx HQ</title>
          <meta name="og:title" content="Globelynx Blog | News & Views From Globelynx HQ" />
          <meta name="description" content="Keep up-to-date with news and views from Globelynx HQ. Hear from our team, our experts & guest contributors, or delve into our top tips for expert interviews!" />
        </Helmet>

        <div className="container-fluid">
          <div className="row">
            <div className="has-text-centered has-padding">
              <h1 className=" has-text-centered">News & Views From Globelynx HQ</h1>
              <p>Keep up-to-date with news and views from Globelynx HQ. Hear from our team, our experts & guest contributors, or delve into our top tips for expert interviews!</p>
            </div>
          </div>
        </div>

        <section className="section">
          <div className="container-fluid">
            <BlogRoll />
          </div>
        </section>
      </Layout>
    );
  }
}
